

.title
{
  /* correct */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 3.2vh;
  color: #ffffff;
  padding: 0 !important;
  margin-bottom: 25px;
  text-transform: uppercase;
  
}
.title-registro-login
{
  /* correct */
  position: absolute;
  font-size: 2.87svh;
  width: 100%;
  padding: 0 22.36%!important;
  top: 0;
  margin: 16% 0;
  text-align: center;
}

.title-datos{
  /* correct */
  padding: 0 3svh !important;
  margin: 10% 0;
  color: white;
  text-transform: uppercase;
}

.toolbar-registro{
  /* correct */
  height: 30.34svh !important;
  margin-bottom: -15.88svh;
}

.margin-horizontal
{
  margin-left: 3.85%;
  margin-right: 3.85%;
}
.margin-contenido{
  margin-bottom: -15.5vh;
  
}
.item-contraseña, .item-correo, .item-nombres 
{
  /* correct */
  height: fit-content;
  gap: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.list-inputs-datos label, .item-correo label , .item-contraseña label, .item-nombres label, .item-telefono label{
  /* correct */
  color: #868189;
  font-weight: 600;
  font-size: 1.65vh !important;
  height:fit-content ;
}

.item-correo input, .item-contraseña input, .item-nombres input{
  /* correcy */
  height: fit-content;
  font-size: 1.65vh !important;
  border-radius: 15px;
  border: 2px solid #C0BCC2;
  padding-left: 8.3%;
  color: var(--color-Dark-Grey);
}

.item-correo textarea, .item-contraseña textarea, .item-nombres textarea{
  /* correcy */
  height: fit-content;
  font-size: 1.65vh !important;
  border-radius: 15px;
  border: 2px solid #C0BCC2;
  padding: 10px;
  padding-left: 8.3%;
  background-color: #edebed;
  color: var(--color-Dark-Grey);
}

.item-correo select, .item-contraseña select, .item-nombres select{
  /* correcy */
  height: fit-content;
  font-size: 1.65vh !important;
  border-radius: 15px;
  border: 2px solid #edebed;
  padding: 10px;
  padding-left: 8.3%;
  color: var(--color-Dark-Grey);
  align-self: stretch;
  background: #edebed url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="0,0 20,0 10,10" fill="black"/></svg>') no-repeat right 10px center;
  background-position: right 10px center;
  background-size: 14px; /* Reduce el tamaño de la flecha */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.item-terminos{
  /* correct */
  display: flex;
  flex-direction: row;
  margin-left: 7%;
  margin-right: 7%;
}

.list-inputs-datos{
  /* correc */
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5.12%;
  width: 100%;
  margin-top: 2.36svh;
  font-size: 1.65vh !important;;
  row-gap: 1.3svh;
  color: #868189;
  font-style: normal;
  font-weight: 600;
  font-family: Montserrat;
  
}

.list-inputs-datos :nth-child(){
  /* correct */
  width: 100%;
}

.registrate {
  /* correct */
  justify-content: center;
  font-size: 1.88svh; 
  margin-top: 3.4vh;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  height: 2.36vh;
  color: #9A9A9A;;
}

.registrate span{
  color: var(--color-Pink-Light);
}

.input-telefono{
  /* correct */
  vertical-align: bottom;
  width: 100%;
  background-color: #F6F4F7;
  border: 2px solid #C0BCC2;
}

.button-principal{
  /* correct */
  background:linear-gradient(286.45deg, #842E6F 6.72%, #D74286 88.39%);
  border-radius: 10px;
  height: 7.11vh;
  margin-left: 3.85%;
  margin-right: 3.85%;
  font-weight: 700;
  font-size: 2.13vh;
  color: #ffffff;
  width: 100%;
  padding: 2.8% 27.77%;
  text-transform: uppercase;
  border: none;
}

.button-contraseña, .button-blanco{
  /* correct contraseña */
  /* correct blanco */
  text-transform:none;
  font-style: normal;
}

.button-contraseña{
  /* correct */
  border: none;
  color: #D74286;
  line-height: 20px;
  font-weight: 700;
  font-size: 1.89vh;
  height: 5.3vh;
  margin: 1vh 0 !important;
  background: transparent;
  width: 100%;
  text-align: end;
  padding-right: 5.55%;
}


.item-telefono
{
  width: 100%;
  margin-top: 2.36svh;
}

.item-telefono label{
  margin-bottom: 1.3svh;
  width: 100%;
  display: block;
  font-size: 1.65vh !important;;
}

.button-blanco label{
  width: -webkit-fill-available;
}

.button-blanco
{
  /* correct */
  background:var(--ion-background-color);
  font-weight: 600;
  font-size: 2.05vh;
  color: #404040;
  border-radius: 15px;
  border-color: #C0BCC2;
  border-style: solid;
  border-width: 2px;
  height: 7.11vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5%;
  
}

.button-blanco img
{
  /* correct */
  align-items: start;
  margin-right: 10px;
  width:4.26vh;
}

.google
{
  /* correct */
  margin-bottom: 2.2vh;
}

.condiciones-checkbox
{
  position: relative;
  display:flex;
  align-items:  flex-start !important;
  font-size: 14px;
  /* margin-left: 19px; */
}

.terminos
{
  color: var(--color-primario-1);
}

label.error{
  /* correct */
  margin-left: 16px;
  color: red;
  width: 90%;
  opacity: 0.8;
  margin-top: 3%;
  margin-bottom: -4%;
  font-size: smaller;
}

input.error{
  /* correct */
  background-color: #FFF3F3;
  border: 2px solid #EB4646;
}

.select-contenedor{
  /* correct */
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  height: fit-content;
  width: 100%;
  height: 60px;
  
}

.select-contenedor input{
  /* correct */
  background: #F6F4F7 !important;
}

.select-contenedor div{
  /* correct */
  border: 2px solid #C0BCC2 !important;
  background: #F6F4F7 !important;
}

.linea-circulo{
  /* correct */
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  align-items: center !important;
  justify-content: center;
  width: 100%;
}
.linea1{
  /* correct */
  width: 34%;
  padding-right: 9%;
  border: 1px solid #CFCFCF;
}
.linea2{
  /* correct */
  width: 34%;
  padding-left: 9%;
  border: 1px solid #CFCFCF;
}
.circulo{
  /* correct */
  font-style: normal;
  font-weight: 700;
  font-size: 3.02vh;
  line-height: 24px;
  text-transform: uppercase;
  color: #404040;
}
.tratamiento-datos{
  /* correct */
  font-family: 'Montserrat';
  font-size: 1.42vh;
  margin-left: 10px;
  color: #C0BCC2;
}
.ver-politicas {
  /* correct */
  text-decoration: underline; /* Agrega una línea de subrayado */
  color: #D74286; /* Cambia el color del texto (puedes ajustarlo) */
  cursor: pointer; /* Cambia el cursor a una mano para indicar que es un enlace */
  font: inherit;
}

.select-nacimiento{
  /* correct */
  display: grid;
  grid-template-columns: 20% 1fr;
  height: 60px;
  column-gap: 2%;
}

.select-nacimiento div{
  /* correct */
  border: 2px solid #C0BCC2 !important;
}

.select-nacimiento div input, .select-contenedor div input{
  /* correct */
  background:url(../../public/assets/icon/flechaGrisSelector.svg) center right 5px / 15px 15px, #F6F4F7 !important;
  background-repeat: no-repeat !important;
}
 
/* ion-popover#popover-calendario::part(backdrop) {
  background:#b5abab;

}
ion-popover#popover-calendario::part(content) {
  width: 90% !important;
  height: 35.66vh !important; 
  min-height: 300px !important;
  left: 5% !important;
  
  
}
ion-popover#popover-calendario .calendar {
  
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  min-height: 300px !important; 
  
}  */




/* .scroll-up 
{
    animation-name: scroll-up;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

@keyframes scroll-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
} */

.loading-content{
  /* correct */
  border-radius:0%
}

@media screen and (min-width: 768px) {
  .title-registro-login{
    margin: 11% 0;
  }
}